import React from 'react';
import css from './Loader.css'
import * as strings from '../../utils/strings'
import * as constants from '../../utils/constants'
import loader from '../../images/loader.gif'
import { Link } from 'gatsby'
import Wrapper from '../Wrapper/Wrapper'

const Loader = (props) =>(
    <Wrapper>
        {props.loading && <div className="wrapperLoader">
            <img className="loader" src={loader}/>
        </div>}
    </Wrapper>
);

export default Loader;