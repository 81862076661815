import React, {Component, Button} from 'react';
import css from './StoreLocatorComponent.css'
import {Col, Row} from "react-flexbox-grid";
import * as utils from '../../utils/utils'
import * as constants from '../../utils/constants'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Wrapper from '../Wrapper/Wrapper'
import {stringsStoreLocator} from '../../utils/strings'
import Textbox from '../../components/Textbox/Textbox'
import Switch from '../../components/Switch/Switch';
import Radio from '../../components/Radio/Radio'
import Store from './Store'
import * as storeServices from '../../services/StoreLocator'
import MapStore from './MapStore'
import Loader from '../Loader/Loader'
import gps from '../../images/gps.svg'
import map from '../../images/map.svg'
import list from '../../images/list.svg'

class StoreLocatorComponent extends Component {
    constructor(args) {
        super(args);
        this.state = {
            rayStores: [{value:2,name:"2 KM",checked:true, id:1}, {value:5,name:"5 KM", id:2}, {value:10,name:"10 KM", id:3}],
            raw_stores:[],//all
            stores:[],
            showMap:false,
            loading:false,
            search:null,

            STACK:25,
            page:1,

            myCoords:typeof localStorage !== `undefined`?localStorage.getItem(constants.COOKIE_POSITION)?JSON.parse(localStorage.getItem(constants.COOKIE_POSITION)):null:null
        }

        this.refListStores = React.createRef();
        this.refPanelSToreLocator = React.createRef();
    }
    

    _getPosition = (success) =>{
        if (typeof navigator !== 'undefined' && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((showPosition)=>{
                if(success)success(showPosition)
            });
        }
    }

    toggleMap = () =>{
        const fakeState = utils.deepCopy(this.state);
        fakeState.showMap = !fakeState.showMap;
        this.setState(fakeState)
    }

    componentDidMount = async() =>{
        const fakeState = utils.deepCopy(this.state);
        const body = {};

        if(fakeState.myCoords){
            body.action = "distance";
            body.latitude = fakeState.myCoords.lat;
            body.longitude = fakeState.myCoords.lng;
            body.distance = 100000000000;
        }

        const response = await storeServices.getStores(body);
        if(response && response.result && response.result.status === 200){
            fakeState.raw_stores = response.result.data;
            this.setState(fakeState,()=>{
                this.loadStores();
            })
        }

        if(this.refListStores && this.refListStores.current && this.refListStores.current.addEventListener)
            this.refListStores.current.addEventListener('scroll', this.trackScrolling);

        //drag commentato
        //this.dragElement(this.refPanelSToreLocator.current)        
    }

    componentWillUnmount = () =>{
        if(this.refListStores && this.refListStores.current && this.refListStores.current.addEventListener)
            this.refListStores.current.removeEventListener('scroll', this.trackScrolling);
    }

    onRayChanged = (input) =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.rayStores.forEach(element => {
            element.checked = false;
        });

        //checko solo se ha gettato la posizione:
        if(fakeState.myCoords){
            const element = fakeState.rayStores.find(x => x.id == input.target.id);
            if(element){
                element.checked = true;
            }
            const body = {};
            body.action = "distance";
            body.latitude = fakeState.myCoords.lat;
            body.longitude = fakeState.myCoords.lng;
            body.distance = input.target.value*1000;
    
            fakeState.page = 1;
            fakeState.pointStore = null;
            fakeState.loading = true;
            this.setState(fakeState,()=>{
                storeServices.getStoresAsync(body,(response)=>{
                    if(response && response.status === 200){
                        fakeState.raw_stores = response.data;
                    }
                },null,()=>{
                    fakeState.loading = false;
                    this.setState(fakeState,()=>{
                        this.loadStores();
                    })
                })
            })
        }else{
            this._getPosition((position)=>{
                if(position && position.coords){
                    //salvo nei cookie per la prossima volta
                    const cookieToSave = {lat:position.coords.latitude, lng:position.coords.longitude} 
                    if(typeof localStorage !== `undefined`)localStorage.setItem(constants.COOKIE_POSITION, JSON.stringify(cookieToSave));
                    fakeState.myCoords = cookieToSave;
                    this.setState(fakeState);
                }
            });
        }
    }

    getPosition = () =>{
        this._getPosition(async (position)=>{
            if(position && position.coords){
                //salvo nei cookie per la prossima volta
                const cookieToSave = {lat:position.coords.latitude, lng:position.coords.longitude} 
                if(typeof localStorage !== `undefined`)localStorage.setItem(constants.COOKIE_POSITION, JSON.stringify(cookieToSave));

                const fakeState = utils.deepCopy(this.state);
                fakeState.myCoords = cookieToSave;
                fakeState.page = 1;
                
                const body = {
                    distance : {latitude:position.coords.latitude, longitude:position.coords.longitude}
                };
                const response = await storeServices.getStores(body);

                if(response && response.result && response.result.status === 200){
                    fakeState.raw_stores = response.result.data;
                }

                if(this.map){
                    this.map.center = cookieToSave;
                }

                this.setState(fakeState)
            }else{
                if(typeof localStorage !== `undefined`)localStorage.removeItem(constants.COOKIE_POSITION);
            }
        });
    }

    trackScrolling = () =>{
        if( this.refListStores.current.scrollTop === (this.refListStores.current.scrollHeight - this.refListStores.current.offsetHeight)){
            this.loadStores();
        }
    }

    loadStores = () =>{
        const fakeState = utils.deepCopy(this.state);
        fakeState.stores =  utils.deepCopy(fakeState.raw_stores).splice(0,fakeState.STACK * fakeState.page);
        fakeState.page++;
        this.setState(fakeState)
    }

    goToPoint = (pointStore) =>{
        const fakeState = utils.deepCopy(this.state);
        fakeState.pointStore = pointStore;
        fakeState.showMap = true;
        this.setState(fakeState);
    }

    onSearchChange = (e) =>{
        const fakeState = utils.deepCopy(this.state);
        fakeState.search = e;
        this.setState(fakeState)
    }

    search = (e) =>{
        e.preventDefault();
        const fakeState = utils.deepCopy(this.state);
        const body = {};
        fakeState.page = 1;
        fakeState.pointStore = null;
        body.action = "search";
        body.query = fakeState.search;

        fakeState.loading = true;
        fakeState.searchedCity = fakeState.search;

        this.setState(fakeState,()=>{
            storeServices.getStoresAsync(body,(response)=>{
                if(response && response.status === 200){
                    fakeState.raw_stores = response.data;
                }
            },null,()=>{
                fakeState.loading = false;
                this.setState(fakeState,()=>{
                    this.loadStores();
                })
            })
        })
    }

    dragElement = (elmnt) =>{
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        elmnt.onmousedown = dragMouseDown;
        let dragging = false;
        
        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
            dragging = false;
        }
        
        const elementDrag = (e) => {
            if(!dragging){
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
    
                console.log(pos2)
    
                elmnt.style.top = (elmnt.offsetTop - (pos2*10)) + "px"
                dragging = true;
                setTimeout(()=>{
                    elmnt.style.top = null;
                    this.props.onStoreLocatorClose();
                    document.onmousemove = null;
                    return;
                }, 300);
            }
            
        }
        
        function closeDragElement(){
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    render() {
        return (
            <Wrapper>
                <Loader loading={this.state.loading}/>

                {this.props.show && <div className="overlayStoreLocator" onClick={this.props.onStoreLocatorClose}/>}
                {this.props.show && !this.state.showMap && <div className="shadowStoreLocator hidden-xs"/>}

                    
                <div className={"storeLocatorComponentWrapper " + (this.props.show?" opened":" closed") + (!this.props.hideFooter?" footer":"")}>
                    <button className="defaultButton buttonOpen" onClick={this.props.onStoreLocatorClose} ref={this.refPanelSToreLocator}>
                        {stringsStoreLocator.btn_store_locator}  <i className="fa fa-search" aria-hidden="true"></i>
                    </button>

                    <div className="contentStoreLocator align-center">
                        <div className="storeControls">
                            <Row className="rowLabels hidden-xs">
                                <Col md={3}>
                                    <label>{stringsStoreLocator.lbl_find_shop}</label>
                                </Col>
                                <Col md={3}>
                                    <label>{stringsStoreLocator.lbl_posizione}</label>
                                </Col>
                                <Col md={1}>
                                    <label>{stringsStoreLocator.lbl_map}</label>
                                </Col>
                                <Col md={5}>
                                    <label>{stringsStoreLocator.lbl_ray}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="visible-xs nopadding">
                                    <p className="infoMobile">{stringsStoreLocator.lbl_info_mobile}</p>
                                </Col>
                                <Col xs={10} md={3} className="nopadding">
                                    <form onSubmit={this.search}>
                                        <Textbox resetOnEnter onChange={this.onSearchChange} placeholder={stringsStoreLocator.placeholder_find_shop} icon={<i className="fa fa-search" />}/>
                                    </form>
                                </Col>
                                <Col xs={2}  md={3} className="gps nopadding">
                                    <svg id="Component_192" onClick={this.getPosition} className="pointer"  data-name="Component 192" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                        <g id="Group_831" data-name="Group 831">
                                            <g id="Rectangle_119" data-name="Rectangle 119" fill="none" stroke="#622181" stroke-width="2">
                                            <rect width="28" height="28" rx="14" stroke="none"/>
                                            <rect x="1" y="1" width="26" height="26" rx="13" fill="none"/>
                                            </g>
                                        </g>
                                        <path id="Icon_metro-target" data-name="Icon metro-target" d="M14.571,7.178H13.388A4.887,4.887,0,0,0,9.321,3.111V1.928h-1.5V3.111A4.887,4.887,0,0,0,3.753,7.178H2.571v1.5H3.753a4.887,4.887,0,0,0,4.067,4.067v1.183h1.5V12.745a4.887,4.887,0,0,0,4.067-4.067h1.183v-1.5Zm-2.709,0H10.692A2.256,2.256,0,0,0,9.321,5.806V4.637a3.386,3.386,0,0,1,2.541,2.541Zm-3.291,1.5a.75.75,0,1,1,.75-.75A.75.75,0,0,1,8.571,8.678Zm-.75-4.041V5.806A2.256,2.256,0,0,0,6.449,7.178H5.28A3.386,3.386,0,0,1,7.821,4.637ZM5.28,8.678H6.449A2.256,2.256,0,0,0,7.821,10.05v1.169A3.386,3.386,0,0,1,5.28,8.678Zm4.041,2.541V10.05a2.256,2.256,0,0,0,1.372-1.372h1.169A3.386,3.386,0,0,1,9.321,11.219Z" transform="translate(5.429 6.072)" fill="#4c0d6a"/>
                                    </svg>
                                </Col>
                                <Col md={1} className="hidden-xs">
                                    <Switch checked={this.state.showMap} className="vertical-align" onChange={this.toggleMap} 
                                        icon={!this.state.showMap?<img src={map} style={{height:"11px",marginLeft:"-1px"}}/>:<img src={list} style={{height:"11px"}}/>}/>
                                </Col>
                                <Col xs={10} md={5} className="nopadding margin-mobile radioWrapper">
                                    <Radio options={this.state.rayStores} onChange={this.onRayChanged}/>
                                </Col>
                                <Col xs={2} className="visible-xs nopadding margin-mobile">
                                    <Switch checked={this.state.showMap} className="vertical-align"  onChange={this.toggleMap}  
                                        icon={!this.state.showMap?<img src={map} style={{height:"11px",marginLeft:"-1px"}}/>:<img src={list} style={{height:"11px"}}/>}/>
                                </Col>
                            </Row>
                        </div>

                        <div className={"storeList " + (this.state.showMap?"gone":"visible")}>
                            <div className="wrapperStores" ref={this.refListStores}>
                                {this.state.stores.map((store,index)=>
                                    <Store goToPoint={this.goToPoint.bind(this,{lat:store.address.latitude,lng:store.address.longitude, id:store.id})} loading={this.state.loading} store={store} key={"store" + index}/>
                                )}
                            </div>
                        </div>

                        {!this.state.showMap && 
                            <div className="fadingList hidden-xs"/>
                        }
                        
                        <div className={"map"} style={{display:this.state.showMap?"":"none"}}>
                            <MapStore searchedCity={this.state.searchedCity} pointStore={this.state.pointStore} coords={this.state.myCoords} places={this.state.raw_stores}/>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
export default StoreLocatorComponent;