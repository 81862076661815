import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Wrapper from '../components/Wrapper/Wrapper'
import { Helmet } from 'react-helmet'

const FetchedMetatags = (props) =>{
    const fetchedData = useStaticQuery(graphql`
        query {
            homepage {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            },
            faq {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            },
            products {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            },
            createYourLook {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            },
            productDetail {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            },
            contactUs {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            },
            findYourColor {
                title,
                description,
                keywords,
                og_description,
                og_image,
                og_image_type,
                og_image_url
            }
        }
    `);
    
    return (
        fetchedData[props.pagename] ?
            <Helmet
                title={fetchedData[props.pagename].title}
                meta={[
                    {
                        property: `og:title`,
                        content: fetchedData[props.pagename].title
                    },
                    {
                        property: `og:type`,
                        content: "website",
                    },
                    {
                        property: `og:image`,
                        content: fetchedData[props.pagename].og_image_url,
                    },
                    {
                        name: `description`,
                        content: fetchedData[props.pagename].description,
                    },
                    {
                        name: `keywords`,
                        content: fetchedData[props.pagename].keywords,
                    },
                    {
                        property: `og:description`,
                        content: fetchedData[props.pagename].description,
                    },
                    
                    {
                        property: `og:image:url`,
                        content: fetchedData[props.pagename].og_image_url,
                    },
                    {
                        property: `og:image:type`,
                        content: "image/jpeg",
                    },
                    {
                        property: `og:image:width`,
                        content: "230",
                    },
                    {
                        property: `og:image:height`,
                        content: "300",
                    },
                ]}/>
        :null
    )
}

export default FetchedMetatags;