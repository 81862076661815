export default class HocProduct{
    constructor(_product) {
        if(_product){
            this.id = _product.id;
            this.categories = _product.categories;
            this.description = _product.description;
            this.currency = _product.currency;
            this.ean = _product.ean;
            this.effects = _product.effects;
            this.hair_colors = _product.hair_colors;
            this.hair_type = _product.hair_type;
            this.image = _product.image;
            this.ingredients = _product.ingredients;
            this.instructions = _product.instructions;
            this.treatments = _product.treatments;
            this.not_contains = _product.not_contains;
            this.month_product = _product.month_product;
            this.name = _product.name;
            this.new = _product.new;
            this.price = _product.price;
            this.applications = _product.applications;
            this.related_products = _product.related_products;
            this.slug = _product.slug;
            this.product_bottom_left_image = _product.product_bottom_left_image;
            this.product_bottom_left_image_alt = _product.product_bottom_left_image_alt;
            this.product_bottom_right_image = _product.product_bottom_right_image;
            this.product_bottom_right_image_alt = _product.product_bottom_right_image_alt;
        }
    }

    static getInstance = (product) =>{
        product = product || null;
        if(!product){
            return undefined;
        }
        return new Product(product);;
    }
}