import './Cascade.css'

export default function Cascade(elements, options) {
  const defaultDelay = 200;


  if(elements && elements.length){ 
    let delay = 0;

    for(let i=0;i<elements.length;i++){
      const element = elements[i];
      const baseAnimation = element.getAttribute("baseAnimation");
      const resetAnimation = element.getAttribute("resetAnimation") == "true"
      

      if(baseAnimation && !isNaN(baseAnimation)){
        delay = baseAnimation;
      }
      delay++;
      if(resetAnimation){
        delay = 0;
      }
 
      element.style.animationDelay = (defaultDelay*delay) + "ms";
      //element.classList.add("fade-in");
    }
  }
}