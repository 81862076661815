import React from 'react';
import {Col, Row} from "react-flexbox-grid";
import { Link } from 'gatsby'
import * as constants from '../../utils/constants'
import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import * as strings from '../../utils/strings'
import Logo from '../../components/Logo/Logo'
import Cookies from 'universal-cookie';

const HeaderBar = (props) =>(
    <Row>
        <Col xs={12} className="header nopadding">
            <Row>
                <Col xs={2} className="nopadding">
                    {props.hamburger}
                </Col>
                <Col xs={8} className="align-center nopadding">
                    <Logo/>
                </Col>

                <Col xs={2} className="nopadding">
                    <div className="wrapperNotification">
                        <span className="material-icons notification pointer" onClick={props.onClickedBell}>
                            notifications
                            <NotificationBadge count={props.notification?1:0} effect={Effect.SCALE} className="badge"/>
                        </span>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>
);

export default HeaderBar;