import React from 'react';
import css from './ProductCard.css'
import {Col, Row} from "react-flexbox-grid";
import { stringsHome } from '../../utils/strings';
import { Spinner } from 'react-bootstrap';
import {Link} from 'gatsby'
import * as constants from '../../utils/constants'
import AniLink from "gatsby-plugin-transition-link/AniLink"

const ProductCard = (props) =>(
    <AniLink duration={0.6} fade to={constants.NAVIGATION_PRODUCT_DETAIL + "/" + props.slug}  state={{ slug: "the-slug"}}>
        <div className={"product-card align-center " + (props.customBackgroundClass?"":"custom-card")} cascade={props.cascade}>
            {props.loading && <Spinner animation="grow" ><span className="sr-only">Loading...</span></Spinner>}

            <img src={props.image && props.image.length>0? props.image[0].image:null}
                    alt={props.image && props.image.length>0?props.image[0].alt:null}/>
            <div className="product-title">
                <h3>{props.name}</h3>
            </div>
            <div className="product-price">
                <h2>{props.price}  €</h2>
            </div>
            <button className="defaultButton">
                {stringsHome.btn_scopri_di_piu}
            </button>
        </div>
    </AniLink>
);

export default ProductCard;