import React, {Component} from 'react';
import * as utils from '../../utils/utils'
import {Col, Row} from "react-flexbox-grid";
import './Store.css'
import {stringsStoreLocator} from '../../utils/strings'

const Store = (props) =>(
    <div className="store" cascade={props.loading?"false":"true"}>
        <div className="leftColumn">
            <span className="icon">
                <span className="material-icons">
                    place
                </span>
            </span>
        </div>
        
        <div className="rightColumn">
            <div className="infoStore">
                <h3 className="nameStore">{props.store.name}</h3>
                {props.store && props.store.address && <p className="address">{props.store.address.formatted.split(",")[0]}</p>}
                {props.store && props.store.address && props.store.address.locality && <p className="address hidden-xs">{props.store.address.locality.postal_code} {props.store.address.locality.name}</p>}
            </div>

            <div className="geolocationStore">
                <p className="distance">{props.store.distance}</p>
                <button className="defaultButton" onClick={props.goToPoint}>{stringsStoreLocator.btn_map}</button>
            </div>
        </div>
    </div>
)

export default Store;