import React, {Component} from 'react';
import css from './Textbox.css'
import {Col, Row} from "react-flexbox-grid";
import * as utils from '../../utils/utils'

class Textbox extends Component {
    constructor(args) {
        super(args);
        this.state = {
            focussed:false,
            value:null
        }
        this.input = React.createRef();
    }

    toggleFocus = () =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.focussed = !fakeState.focussed;
        if(fakeState.focussed) this.input.current.focus();
        else{
            if(this.props.onBlur) this.props.onBlur();
        }
        this.setState(fakeState);
    }

    setFocus = () =>{
        const fakeState = utils.deepCopy(this.state)
        if(!fakeState.focussed){
            fakeState.focussed = true;
            this.input.current.focus();
            this.setState(fakeState);
        }
    }

    changeValue = (e) =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.value = e.target.value;
        this.setState(fakeState,()=>{
            if(this.props.onChange)this.props.onChange(fakeState.value);
        })
    }

    onKeyUp = (e) =>{
        if(this.props.resetOnEnter && (e.key === 'Enter' || e.keyCode === 13)){
            this.input.current.blur(); 
        }
    }


    render() {
        return (
            <div className={"wrapperTextbox " + (this.props.form?"form-group":"")}>
                <div className="wrapperInput" cascade={this.props.cascade}>

                    {this.props.type === "textarea"?
                        <textarea 
                            onFocus={this.toggleFocus}
                            onBlur={this.toggleFocus}
                            ref={this.input}
                            onKeyUp={this.onKeyUp}
                            onChange={this.changeValue}
                            placeholder={this.props.placeholder} 
                            className={(this.props.form?"form-control":"") + (this.props.error?" error":"")}/>
                    :
                        <input 
                            type={this.props.type?this.props.type:"text"}
                            onFocus={this.toggleFocus}
                            onBlur={this.toggleFocus}
                            onSubmit={this.toggleFocus}
                            ref={this.input} 
                            onKeyUp={this.onKeyUp}
                            defaultValue={this.props.defaultValue}
                            onChange={this.changeValue}
                            placeholder={this.props.placeholder} 
                            className={(this.props.form?"form-control":"") + (this.props.error?" error":"")}/>
                    }
                    {
                        this.props.movingPlaceholder?
                            <label className={"label " + (this.state.focussed || this.state.value ? "focussedLabel ":" ") + (this.props.error?"error":"")} onClick={this.setFocus}>
                                {this.props.errorText && this.props.error?this.props.errorText:this.props.movingPlaceholder}
                            </label>
                        :
                            this.props.error && <label className={"label focussedLabel error"}>
                                {this.props.error}
                            </label>
                        }
                    
                    <span className="icon pointer" onClick={this.toggleFocus} >
                        {this.props.icon}
                    </span>
                </div>
            </div>
        )
    }
}

export default Textbox;