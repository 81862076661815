import Request from './Request'
import * as constants from '../utils/constants'

export async function getStores(body){
    const request = new Request(constants.API_GET_STORES, "post", body);
    return await request.executeRequest();
}

export function getStoresAsync(body,cbSuccess,cbError, cbFinally){
    const request = new Request(constants.API_GET_STORES, "post", body);
    request.executeRequestAsync(cbSuccess,cbError, cbFinally);
}