import React, {Component, Button} from 'react';
import css from './Container.css'
import {Col, Row} from "react-flexbox-grid";
import Sidebar from './Sidebar'
import * as utils from '../../utils/utils'
import StoreLocatorComponent from '../../components/StoreLocatorComponent/StoreLocatorComponent'
import  {Modal}  from 'react-bootstrap';
import { Link } from 'gatsby'
import * as constants from '../../utils/constants'
import { withRouter } from 'react-router-dom';
import ProductCard from '../../components/ProductCard/ProductCard'
import { stringsContainer } from '../../utils/strings';
import * as hamburgerCss from '../../../node_modules/hamburgers/dist/hamburgers.css'
import { Helmet } from "react-helmet"
import '../../App.css'
import HeaderBar from './HeaderBar';
import { navigate,withPrefix } from "gatsby"
import tempProductImage from '../../images/temp_product.png';
import Cookies from 'universal-cookie';
import * as productFunctions from '../../services/Products';
import * as metatagsFunctions from '../../services/Metatags'
import HocProduct from '../../utils/hoc/HocProduct'
import Loader from '../../components/Loader/Loader'
import Wrapper from '../../components/Wrapper/Wrapper';
import CustomBall from '../../components/CustomBall/CustomBall';
import favicon from '../../images/favicon.ico';
import FetchedMetatags from '../../pages/FetchedMetatags'
import Cascade from '../../components/Cascade/Cascade'
import { Scrollbars } from 'react-custom-scrollbars';

class Container extends Component {
    constructor(args) {
        super(args);
        this.state = {
            sidebar:false,
            sidebarHamburger:false,
            popup:false,
            popupTemp:false,
            styleBall1:{},
            styleBall2:{},
            styleBall3:{},//popup
            styleBall4:{},//popup
            styleCustomBalls:[],
            product_of_the_month:{},
            notification:false,
            loading_product_of_the_month:false,
            metatags:[],
            storeLocator:false,
        }
    }

    refCustomBalls = [];

    toggleSidebar = () =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.sidebarHamburger = !fakeState.sidebarHamburger;

        if(fakeState.sidebarHamburger){
            if(typeof document !== `undefined`)document.body.style.overflowY = "hidden";
        }else{
            if(typeof document !== `undefined`)document.body.style.overflowY = "auto";
        }

        this.setState(fakeState,()=>{
            setTimeout(()=>{

                fakeState.sidebar = !fakeState.sidebar;
                this.setState(fakeState)
            }, 400);
        })
    }

    toggleStoreLocator = () =>{
        const fakeState = utils.deepCopy(this.state);
        fakeState.storeLocator = !fakeState.storeLocator;

        if(fakeState.storeLocator)document.body.style.overflowY = "hidden"
        else document.body.style.overflowY = "auto"

        this.setState(fakeState)
    }

    togglePopup = () =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.popup = !fakeState.popup;
        const cookies = new Cookies();


        if(cookies.get(constants.COOKIE_POPUP) != fakeState.product_of_the_month.id){
            //set the cookie :
            cookies.set(constants.COOKIE_POPUP, fakeState.product_of_the_month.id,{maxAge:99999999});
        }

        fakeState.notification = false;
        this.setState(fakeState)
    }

    componentDidMount = async() =>{
        const fakeState = utils.deepCopy(this.state);
        const cookies = new Cookies();

        var s = document.createElement("script"),
        tag = document.getElementsByTagName("script")[0];
        s.src="https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s,tag);

        if(typeof screen !== `undefined`){
            //screen.orientation.lock('landscape');
        }

        if(typeof window !== `undefined` && this.props.withScroll){
            window.addEventListener('scroll', this.handleScroll, true);
        }
        fakeState.loading_product_of_the_month = true;

        if(this.props.customBalls){
            this.props.customBalls.forEach((element,index) => {
                this.refCustomBalls.push(React.createRef());
            });
        }

        //animation elements:
        this.setState(fakeState,()=>{
            productFunctions.getMonthlyProduct((response)=>{
                if(response.status === 200 && response.data && response.data.length>0){
                    fakeState.product_of_the_month = new HocProduct(response.data[0]);

                     //check if notifications:
                     fakeState.notification = cookies.get(constants.COOKIE_POPUP) != fakeState.product_of_the_month.id;
                }
            },(err)=>{
                console.error(err)
            },()=>{
                fakeState.loading_product_of_the_month = false;
                this.setState(fakeState);
            });
        })

        //iubenda e ga:
        if(typeof document !== `undefined` && typeof window !== `undefined` ){
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.innerHTML =
                'var _iub = _iub || []; _iub.csConfiguration = {"gdprAppliesGlobally":false,"whitelabel":false,"lang":"it","siteId":1997409,"cookiePolicyId":14605460, "banner":{ "position":"float-top-center","textColor":"black","backgroundColor":"white","acceptButtonDisplay":true,"acceptButtonColor":"#ffc72f","acceptButtonCaptionColor":"#622181","customizeButtonDisplay":true,"customizeButtonColor":"#622181","customizeButtonCaptionColor":"white","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white" }}';
            script.async = true;

            const script2 = document.createElement("script");
            script2.type = "text/javascript";
            script2.src = "//cdn.iubenda.com/cs/iubenda_cs.js";

            const scriptGA = document.createElement("script");
            scriptGA.type = "text/javascript";
            scriptGA.src = "https://www.googletagmanager.com/gtag/js?id=UA-177099670-1";
            scriptGA.async = true;

            document.body.appendChild(script);
            document.body.appendChild(script2);

        }
    }

    componentDidUpdate = () =>{
        if(!this.props.dontAnimateOnUpdate) this.animate();
        if(this.props.customBalls){
            this.props.customBalls.forEach((element,index) => {
                if(!this.refCustomBalls[index] || !this.refCustomBalls[index].current){
                    this.refCustomBalls[index] = React.createRef();
                }
            });
        }
    }

    componentWillUnmount = () =>{
        if(typeof window !== `undefined` && this.props.withScroll){
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    animate = () =>{
        const elements = document.querySelectorAll('[cascade="true"]');
        Cascade(elements);
    }

    lastScrollTop = 0;
    handleScroll = (e) =>{
        const fakeState = utils.deepCopy(this.state)
        let st = window.pageYOffset || document.documentElement.scrollTop;
        const defaultOffsetBall = 50;


        var scrollPosition = window.pageYOffset;
        var windowSize     = window.innerHeight;
        var bodyHeight     = document.body.offsetHeight;
        let lt = Math.max(bodyHeight - (scrollPosition + windowSize), 0);

        if(st>100 && lt>250){
            if (st > this.lastScrollTop){
                fakeState.styleBall1 = {
                    top:0
                }

                if(this.props.customBalls){
                    this.props.customBalls.forEach((el,index)=>{
                        try{
                            let oldTop = 0;
                            if(!fakeState.styleCustomBalls[index]){
                                oldTop = this.refCustomBalls[index].current.offsetTop;
                            }else{
                                oldTop = fakeState.styleCustomBalls[index].oldTop;
                            }

                            fakeState.styleCustomBalls[index] = {
                                oldTop:oldTop,
                                top:oldTop
                            }
                        }catch(e){}

                    });
                }
            }else{
                fakeState.styleBall1 = {
                    top:defaultOffsetBall
                }
                if(this.props.customBalls){
                    this.props.customBalls.forEach((el,index)=>{
                        try{
                            fakeState.styleCustomBalls[index] = {
                                top:fakeState.styleCustomBalls[index].oldTop + defaultOffsetBall,
                                oldTop:fakeState.styleCustomBalls[index].oldTop
                            }
                        }catch(e){}

                    });
                }

            }
            if(this.props.updateCircleImages)  this.props.updateCircleImages(0);
            this.setState(fakeState)
        }else if(lt <= 200){
            if(this.props.updateCircleImages)  this.props.updateCircleImages(defaultOffsetBall);
        }

        this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }

    setPopupFadeOut = () =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.popupTemp = fakeState.popup;
        this.setState(fakeState)
    }


    render() {
        const hamburger =  <button className={"hamburger hamburger--rdx hamburger--collapse " + (this.state.sidebarHamburger?"is-active":"")} type="button" onClick={this.toggleSidebar}>
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </button>;

        return (
            <div className={"blended-background " + (this.props.noLateralPadding?"noLateralPadding ":"") + (this.props.withScroll && "blended-backgroundWithScroll ")}
                style={this.state.popup ? {maxHeight:"100vh",overflow:"hidden"}:null}>
                {!this.props.metatagsInChild && <FetchedMetatags pagename={this.props.pageName}/>}

                <Loader loading={this.props.loading}/>
                <Helmet>
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"/>
                    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
                    <link href='https://fonts.googleapis.com/css?family=Lato:normal,300,400,700,900' rel='stylesheet' type='text/css'/>
                    <link href={'../../App.css'} rel='stylesheet' type='text/css'/>
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
                    <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                    <meta name="theme-color" content="#EFEAE4" />
                    <meta http-equiv="ScreenOrientation" content="autoRotate:disabled"/>
                    <link rel="icon"  type="image/png"  href={favicon}></link>
                </Helmet>

                {!this.props.ballDisabled &&
                    this.props.customBalls && this.props.customBalls.length>0?
                        <Wrapper>
                            {this.props.customBalls.map((customBall,index)=>
                                <span key={"custom-ball-" + (index+1)} ref={this.refCustomBalls[index]}
                                    className={("ball custom-ball custom-ball-" + (index+1) + " " + customBall.className)}
                                    style={this.state.styleCustomBalls[index]}/>
                            )}
                        </Wrapper>
                    :
                        <Wrapper>
                            <div className="ball ball-1" style={this.state.styleBall1} />
                            {/*<div className="ball ball-2" style={this.state.styleBall2}/>*/}
                        </Wrapper>
                }

                <div className={"blendend "  + (this.props.withScroll && "blendendWithScroll")} />

                <Sidebar
                    show={this.state.sidebar}
                    product_of_the_month={this.state.product_of_the_month}
                    loading_product_of_the_month={this.state.loading_product_of_the_month}
                    onSlideClose={this.toggleSidebar}
                    cascade={this.state.sidebar}
                    hamburger={hamburger}/>

                <div onAnimationEnd={this.setPopupFadeOut}
                    className={"backgroundPopup " + (this.state.popup?"fade-in ":"fade-out ") + (!this.state.popup?(this.state.popupTemp?"":"zIndexGone"):"")}
                    onClick={this.togglePopup}/>

                <div className="wrapperBlended-background-popup">
                    <div
                        className={"blended-background-popup " + (this.state.popup?"fade-in ":"fade-out ") + (!this.state.popup?(this.state.popupTemp?"":"zIndexGone "):"") }
                        onAnimationEnd={this.setPopupFadeOut}>
                        <div className="ball ball-1"/>
                        <div className="ball ball-2" />
                        <div className="blendend"/>

                        <span class="material-icons pointer" onClick={this.togglePopup}>
                            clear
                        </span>
                        <div className="customBackgroundClassProductOfTheMonth">
                            <h1 className="align-center">{stringsContainer.lbl_title_product_of_the_month}</h1>
                            <ProductCard
                                loading={this.state.loading_product_of_the_month}
                                customBackgroundClass={true}
                                image={this.state.product_of_the_month.image}
                                name={this.state.product_of_the_month.name}
                                slug={this.state.product_of_the_month.slug}
                                price={this.state.product_of_the_month.price}/>
                        </div>
                    </div>
                </div>


                <HeaderBar hamburger={hamburger} onClickedBell={this.togglePopup} notification={this.state.notification}/>

                <Row >
                    <Col xs={12} className="nopadding">
                        <div className={"nopadding mainContainer " + (this.props.fluid?"container-fluid ":"container ") + (this.props.loading?"dynamicHeight ":"") + (this.props.verticalAligned?"verticalAligned ":"") + (this.props.withScroll && "contentWithScroll ")} >
                            {this.props.title && <h1 className="container-title align-center" cascade="true">{this.props.title}</h1>}
                            {this.props.subtitle && <h2 className="container-subtitle align-center" cascade="true">{this.props.subtitle}</h2>}
                            {this.props.children}
                        </div>

                        {/*<Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={200}
                        thumbMinSize={30}
                        {...this.props}>

                        </Scrollbars>*/}
                    </Col>
                </Row>

                {!this.props.hidefooter &&

                <div>
                  <div className={"socials"}>
                    <div className={"socials_inner"}>
                      <div>
                        CONTATTACI
                      </div>

                      <div className={"social_icon"}>
                        <a target="_blank" href="https://www.instagram.com/biokeratinach8/"><i class="fa fa-instagram"></i></a>
                      </div>
                      <div className={"social_icon"}>
                        <a target="_blank" href="https://www.facebook.com/biokeratinach8"><i class="fa fa-facebook"></i></a>
                      </div>
                      <div className={"social_icon whatsapp_icon_mobile"}>
                        <a target="_blank" href="tel:+39-342-365-3833"><i class="fa fa-whatsapp"></i></a>
                      </div>
                      <div className={"social_icon whatsapp_icon_desktop"}>
                        <a href="https://wa.me/00393423653833">
                          <i class="fa fa-whatsapp"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={"footer "}>
                      <span className="lblFooter">{stringsContainer.lbl_footer}</span>
                  </div>
                </div>

                }

                {!this.props.storeHidden &&
                    <StoreLocatorComponent hideFooter={this.props.hidefooter} show={this.state.storeLocator}  onStoreLocatorClose={this.toggleStoreLocator} />}

            </div>
        );
    }
}
export default Container;
