import React, {Component, Button} from 'react';
import css from './Sidebar.css'
import {Col, Row} from "react-flexbox-grid";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Textbox from '../../components/Textbox/Textbox'
import ProductCard from '../../components/ProductCard/ProductCard'
import { stringsContainer,stringsSidebar } from '../../utils/strings';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as constants from '../../utils/constants'
import * as utils from '../../utils/utils'
import { withPrefix } from 'gatsby';
import Logo from '../../components/Logo/Logo'
import tempProductImage from '../../images/temp_product.png'
import Cascade from '../../components/Cascade/Cascade'
import Wrapper from '../../components/Wrapper/Wrapper'
import { navigate } from "gatsby"
import { navigateTo } from 'gatsby-link';

class Sidebar extends Component {
    constructor(args) {
        super(args);
        this.state = {
            sidebar:false,
            searchText:"",
            doCascade:false
        }
    }

    timeout = null;

    search = (e) =>{
        e.preventDefault();
        const fakeState = utils.deepCopy(this.state)
        if(fakeState.searchText && fakeState.searchText.trim().length>0 && document){
            //navigateTo(constants.NAVIGATION_PRODUCTS + "?q=" + fakeState.searchText)
            document.location.href = constants.NAVIGATION_PRODUCTS + "?q=" + fakeState.searchText;
        }
    }

    onSearchChange = (value) =>{
        const fakeState = utils.deepCopy(this.state)
        fakeState.searchText = value;
        this.setState(fakeState)
    }

    componentWillUnmount = () =>{
        if(typeof document !== `undefined`)document.body.style.overflowY = "auto";
    }

    componentDidMount = () =>{
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', vh + `px`);
    }

    componentDidUpdate = (props, state) =>{
        if(props.show !== this.props.show){
            clearTimeout(this.timeout)
            this.timeout = setTimeout(()=>{
                this.setState({
                    doCascade:this.props.show
                },()=>{
                    const elements = document.querySelectorAll('.sidebar [cascade="true"]');
                    Cascade(elements);
                })
            }, 500);
        }
    }

    render() {
        return (
            <SlidingPane
                isOpen={this.props.show}
                from="left"
                hideHeader={true}
                className="slideSidebar"
                overlayClassName="overlaySlideSidebar"
                onRequestClose={this.props.onSlideClose}>
                    <div className="content sidebar ">
                        <div className="blended"/>
                        
                        <Row>
                            <Col xs={4} md={2} className="header">
                                {this.props.hamburger}
                            </Col>
                            <Col xs={8} md={10} className="header align-center">
                                <Logo light/>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={12} className="align-center">
                                <div  className="custom-container-sidebar">
                                    {this.state.doCascade &&
                                        <Wrapper>
                                            <form  cascade="true" onSubmit={this.search}>
                                                <Textbox onChange={this.onSearchChange} placeholder={stringsContainer.placeholderTextboxCerca} icon={<i className="material-icons">search</i>}/>
                                            </form>

                                            <ul>
                                                <li cascade="true"><AniLink duration={0.6} fade to={constants.NAVIGATION_PRODUCTS}>{stringsSidebar.li_products}</AniLink></li>
                                                {/*<li><Link to={constants.NAVIGATION_FAQ}>{stringsSidebar.li_faq}</Link></li>*/}
                                                <li cascade="true"><AniLink duration={0.6} fade to={constants.NAVIGATION_CONTACT_US}>{stringsSidebar.li_contact_us}</AniLink></li>
                                            </ul>

                                            <AniLink duration={0.6} fade to={constants.NAVIGATION_PRODUCT_DETAIL + "/" + this.props.product_of_the_month.slug}  state={{ slug: "the-slug"}}>
                                                <ProductCard 
                                                    cascade="true"
                                                    image={this.props.product_of_the_month.image}
                                                    slug={this.props.product_of_the_month.slug}
                                                    loading={this.props.loading_product_of_the_month}
                                                    name={this.props.product_of_the_month.name}
                                                    price={this.props.product_of_the_month.price}/>
                                            </AniLink>
                                        </Wrapper>

                                    }
                                    
                                    
                                </div>
                            </Col>
                        </Row>
                    </div>
            </SlidingPane>

            
        );
    }
}
export default Sidebar;