import Request from './Request'
import * as constants from '../utils/constants'

export function getMonthlyProduct(cbSuccess, cbFail, cbFinally){
    const request = new Request(constants.API_GET_MONTHLY_PRODUCT, "get", null);
    request.executeRequestAsync(cbSuccess,cbFail,cbFinally);
}
export function getProducts(filters,cbSuccess, cbFail, cbFinally){
    const request = new Request(constants.API_GET_PRODUCTS, "get", filters);
    request.executeRequestAsync(cbSuccess,cbFail,cbFinally);
}
export async function getProductDetail(slugProduct){
    const request = new Request(constants.API_GET_PRODUCT_DETAIL + slugProduct, "get", null);
    return await request.executeRequest();
}

//filtri:
export async function getProductCategories(){
    const request = new Request(constants.API_GET_PRODUCT_CATEGORIES, "get", null);
    return await request.executeRequest();
}
export async function getHairType(){
    const request = new Request(constants.API_GET_HAIR_TYPE, "get", null);
    return await request.executeRequest();
}
export async function getHairColor(){
    const request = new Request(constants.API_GET_HAIR_COLOR, "get", null);
    return await request.executeRequest();
}
