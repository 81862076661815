import React, {Component} from 'react';
import css from './Radio.css'
import * as utils from '../../utils/utils'

const Radio = (props) =>(
    <span className="wrapperRadio">
        {props.options && props.options.map((option)=>
            <label className="container" key={"option_" + option.id}>
                <span className="text">{option.name}</span>
                <input type="radio" name="radio" value={option.value}  onChange={props.onChange} id={option.id}/>
                <span className="checkmark"></span>
            </label>
        )}
    </span>
)

export default Radio;