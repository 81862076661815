import React, {Component} from 'react';
import css from './Switch.css'
import * as utils from '../../utils/utils'

const Switch = (props) =>(
    <label className={"switch " + (props.className)}>
        <input checked={props.checked} type="checkbox" onChange={props.onChange}/>
        <span className="slider round"/>
        {props.icon && <span className={"icon " + (props.checked ? "iconMoved":"")}>
            {props.icon}
        </span>}
    </label>
)

export default Switch;