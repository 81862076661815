import * as contants from '../../utils/constants'
import * as utils from '../../utils/utils'
import './MapStore.css'
import React, {Component} from 'react';
import {GoogleApiWrapper,Map, Marker, InfoWindow} from 'google-maps-react';//da eliminare forse
import icon_marker from '../../images/marker.svg'
import  MarkerClusterer from 'node-js-marker-clusterer';
import {stringsStoreLocator} from '../../utils/strings'


export class MapStore extends Component {
  mapStyle = [
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "red"
            },
            {
                "visibility": "off"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#4b0e69"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#320D2C"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b143e6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "color": "#622181"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#320D2C"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#320D2C"
            },
            {
                "lightness": 17
            }
        ]
    }
]

  constructor(args) {
    super(args);
    this.state = {
      selectedPlace: null,
      activeMarker: null,
      showingInfoWindow: false,
      mapContainerStyle:"",
      clickedStoreFromMap:null,
      pointStore: this.props.pointStore,
      searchedCity: null
    }
  }
  refMap = React.createRef();

  onMarkerClicked = (store, props, marker, e) =>{
    this.setState({
      selectedStore: store,
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

   removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
    }

  componentDidUpdate = (oldProps,oldState) =>{
      //const map = new google.maps.Map(this.refMap.current);
      /*const markers = [];
      for(let i=0;i<this.props.places.length;i++){
        markers.push({lat:this.props.places[i].address.latitude, lng:this.props.places[i].address.longitude});
      }*/

      if(oldProps.searchedCity !== this.props.searchedCity){
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          'address': this.props.searchedCity
        }, (results, status)=> {
          if (status == google.maps.GeocoderStatus.OK) {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            this.setState({
                searchedCity:{lat:lat, lng:lng}
            });
          }
        });
      }

      if( JSON.stringify(this.props.pointStore) !== JSON.stringify(oldState.pointStore) && this.props.places && this.props.pointStore){
        const marker = new google.maps.Marker({
            position: { lat:  this.props.pointStore.lat, lng: this.props.pointStore.lng },
            map: this.refMap.current.map,
            icon: icon_marker,
        });

        const selectedStore = this.props.places.find((place)=>{
            return place.id === this.props.pointStore.id;
        })

        this.setState({
            pointStore:this.props.pointStore,
            
            activeMarker : marker,
            showingInfoWindow : true,
            selectedStore:selectedStore,
            clickedStoreFromMap:{lat: this.props.pointStore.lat, lng: this.props.pointStore.lng}
        });
      }

      if(this.props.places && this.props.places.length>0 && !this.state.alreadyInitializated){
        let markers = [];

        this.props.places.map((location) => {
            if(location && markers.filter((oldLocation)=>{
                return oldLocation.address.latitude === location.address.latitude && oldLocation.address.longitude === location.address.longitude
            }).length===0){
                markers.push(location)
            }
        });


        markers = markers.map((location) => {
            const marker = new google.maps.Marker({
              position: { lat: location.address.latitude, lng: location.address.longitude },
              map: this.refMap.current.map,
              icon: icon_marker,
            });

            marker.addListener("click", () => {
                this.setState({
                    activeMarker : marker,
                    showingInfoWindow : true,
                    selectedStore:utils.deepCopy(location),
                    clickedStoreFromMap:{lat: marker.position.lat(), lng: marker.position.lng()}
                });
            });
            return marker;
        });
        //markers = this.removeDuplicates(markers, "address.latitude");
        

        this.setState({
            alreadyInitializated: true
        });
    
        new MarkerClusterer(this.refMap.current.map, markers,{
            styles: [{
              width: 40,
              height: 40,
              url: utils.getGoogleClusterInlineSvg("#FFC72F"),
              textColor: '#622181',
            }],
        });
    }
      
  }

  goToGMaps = (marker) =>{
    const coordinates = ""
    console.log(marker)
    const url = "https://www.google.com.sa/maps/search/"+ coordinates +",12.21z?hl=en";
    window.open(url, '_blank');
  }

  closeTooltip = () =>{
    this.setState({
        selectedStore: null,
        selectedPlace: null,
        activeMarker: null,
        showingInfoWindow: false
      });
  }

  render() {
    const defaultCoords = this.props.coords?{lat:this.props.coords.lat, lng:this.props.coords.lng}:{lat: 45.0781, lng: 7.666667};
    let direction_url = "https://www.google.com/maps/dir/?api=1&destination=" ;
    if(this.state.activeMarker){
        //direction_url += this.state.activeMarker.position.lat() + "," + this.state.activeMarker.position.lng()
        direction_url += this.state.selectedStore.name + "," + this.state.selectedStore.address.formatted
    }
    return (
      <Map google={this.props.google} 
        ref={this.refMap}
        zoom={17}
        onClick={this.closeTooltip}
        style={{width: '100%', height: '100%', position: 'relative'}}
        onReady={this.onMapReady}
        containerStyle={utils.isMobile()?{position:"relative"}:null}
        initialCenter={defaultCoords} 
        center={this.state.searchedCity?this.state.searchedCity: this.state.clickedStoreFromMap?this.state.clickedStoreFromMap : this.state.pointStore?this.state.pointStore: this.props.coords?{lat:this.props.coords.lat, lng:this.props.coords.lng}:defaultCoords}
        styles={this.mapStyle}>

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
            <div className="tooltipMarker">
              <b className="title">{this.state.selectedStore && this.state.selectedStore.name}</b>
              <br/><br/>
              <p className="address">{this.state.selectedStore && this.state.selectedStore.address && this.state.selectedStore.address.formatted}</p>
              <a href={direction_url} target="_blank" className="defaultButton">{ stringsStoreLocator.btn_driving_directions}</a>
            </div>
        </InfoWindow> 
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: (contants.GOOGLE_API_KEY),
  id:"7de6c6bd8331ca5",
  language: "it"
})(MapStore)